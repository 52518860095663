import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Hide your Dock without Losing your Mind",
  "date": "2015-07-14T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1023px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.54545454545455%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFTRWhLLKAf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQMAAhETBP/aAAgBAQABBQLlbqbthJ0OtF+iFx3/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAASExEZGh/9oACAEBAAY/AsJSOyS3sro4P//EABsQAQADAQADAAAAAAAAAAAAAAEAESExUWGR/9oACAEBAAE/Ieotu1kFRPljG2v3AurCqazzHPOz/9oADAMBAAIAAwAAABCn7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxALC//EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQIBAT8Qxcv/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFxgaH/2gAIAQEAAT8QVRp3hb58YNoqg8m+sZCrva/cEhlsGGFFytV59ZXEVylTP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "desktop",
          "title": "desktop",
          "src": "/static/17eea3582b244c389815b4974044915b/1c7bc/desktop.jpg",
          "srcSet": ["/static/17eea3582b244c389815b4974044915b/35f54/desktop.jpg 275w", "/static/17eea3582b244c389815b4974044915b/d7854/desktop.jpg 550w", "/static/17eea3582b244c389815b4974044915b/1c7bc/desktop.jpg 1023w"],
          "sizes": "(max-width: 1023px) 100vw, 1023px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Yesterday I decided to clean up all the files that were piling up on my desktop, I changed my wallpaper and tried hiding my dock to see how I would like it.`}</p>
    <p>{`Having the extra space is nice when working from my laptop, but what appealed to me even more was the fact that I could focus on what I was working on `}<em parentName="p">{`right now`}</em>{` instead of everything else I `}<em parentName="p">{`could`}</em>{` be doing. No Slack or Mail notification badge in sight.`}</p>
    <p>{`Every time I had tried that in the past I ended up turning it back off but I couldn’t put my finger on why. My friend `}<a parentName="p" {...{
        "href": "https://twitter.com/waynespiegel/status/620953934711029760"
      }}>{`Wayne Spiegel nailed it`}</a>{`: the delay before the dock comes up is painfully long and the animation is super slow. Painfully slow.`}</p>
    <p>{`Apparently you can change the delay and animation duration. Here’s how to do it:`}</p>
    <pre><code parentName="pre" {...{}}>{`defaults write com.apple.dock autohide-delay -float 0;
defaults write com.apple.dock autohide-time-modifier -float 0.5;
killall Dock
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      